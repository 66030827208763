.survey-container {
  max-width: 600px;
  margin: 2em auto;
  padding: 1em;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.survey-header {
  text-align: center;
  color: #333;
}

.survey-intro {
  font-size: 1em;
  margin-bottom: 2em;
  text-align: center;
}

.survey-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.survey-feedback {
  margin-top: 2em;
  width: 100%;
}

.feedback-label {
  display: block;
  margin-bottom: 0.5em;
}

.feedback-textarea {
  width: 100%; /* Adjusted to be full width without calc */
  height: 120px;
  padding: 0.5em;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
}

.submit-button {
  margin-top: 2em;
  padding: 0.5em 1em;
  font-size: 1.2em;
  color: white;
  background-color: #5cb85c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Optional: if you want the button to be full width */
}

.submit-button:hover {
  background-color: #4cae4c;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.survey-question-text,
.survey-question {
  width: 100%; /* Ensure question text and questions are full width */
  margin-top: 0.3em;
  margin-bottom: 0.3em; /* Space between each question */
}

.radio-group label {
  display: block; /* Ensures each option is on a new line */
}

.radio-option {
  display: block; /* Ensures options do not line up next to each other */
  margin-top: 0.2em; /* Space between options */
}

/* Additional style to align the text properly for all questions */
label {
  text-align: left;
  display: inline-block; /* Align text labels to the left */
  margin-left: 0;
}
